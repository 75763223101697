export const texts = {
    aboutEN: `
    <!DOCTYPE html>
    <html lang="en">
    <head>
    <meta charset="UTF-8">
    <title>ClinicaMatch Platform Overview</title>
    </head>
    <body>
        <h1>Usage</h1>
        <br>
        <ol>
            <li><strong>Initial Query Entry:</strong> Users enter their initial queries in natural language on the platform without requiring any specific knowledge or terminology.</li>
            <li><strong>Identification of Relevant Research:</strong> Based on the initial query, the AI agent identifies the most relevant clinical research.</li>
            <li><strong>Natural Conversation Environment:</strong> The AI chatbot and agent provide a natural conversational environment, gather detailed information from the user, and answer their inquiries.</li>
            <li><strong>Presentation of Results:</strong> At the end of the conversation, users can find clinical research most suited to their health condition and interests.</li>
        </ol>
        <br>
        <h2>Technological Features</h2>
        <ul>
            <li><strong>Semantic Understanding:</strong> The platform deeply understands the user's intent and context using advanced technologies like neural-IR.</li>
            <li><strong>Personalized Responses:</strong> Large AI language models generate personalized, explanatory, and accurate information based on the information received from the user.</li>
            <li><strong>Multi-Language Support:</strong> The platform provides support in multiple languages, enabling users who speak different languages to fully benefit from it.</li>
        </ul>
        <br>
        <h2>Current Limitations</h2>
        <ul>
            <li><strong>Data Limitation:</strong> Currently, the database used in ClinicaMatch is limited to a small dataset related to cancer.</li>
            <li><strong>User Experience:</strong> Being in the early stages, the user interface and experience may need further development.</li>
        </ul>
        <br>
        <h2>Continuity of Development Process</h2>
        <ul>
            <li><strong>Expansion of the Dataset:</strong> The use case will be expanded by integrating broader datasets covering various disease types.</li>
            <li><strong>Advanced Natural Language Processing & Information Extraction:</strong> Algorithms and techniques will be developed to accommodate more complex user needs.</li>
            <li><strong>User Feedback Mechanism:</strong> User feedback will be collected to improve user experience, and the platform will be updated with this information.</li>
        </ul>
        <br>
        <h2>Technological Potential</h2>
        <h3>Healthcare Application Examples:</h3>
        <ol>
            <li><strong>Disease Diagnosis and Tracking Systems:</strong> Customized queries based on symptoms and patient histories can be used for early disease diagnosis.</li>
            <li><strong>Treatment Recommendation System:</strong> A system could be developed that recommends treatment methods specific to the patient's condition.</li>
            <li><strong>Health Information Portal:</strong> An information portal could be established to provide users easy access to general health information.</li>
            <li><strong>Remote Health Monitoring and Management:</strong> Remote monitoring and health management systems could be integrated for patients with chronic diseases.</li>
            <li><strong>Health Research and Data Analysis:</strong> Tools for analyzing large health data sets could be developed, providing critical information for epidemiological studies and public health research.</li>
        </ol>
        <br>
        <h3>Applications in Other Sectors:</h3>
        <ol>
            <li><strong>Customer Service and Support:</strong> AI-powered chatbots can be used in customer service processes.</li>
            <li><strong>Finance and Banking:</strong> In the finance sector, AI-based systems can analyze customer spending habits to offer personalized banking advice.</li>
        </ol>
    </body>
    </html>
    `,
  
    aboutTR: `
    <!DOCTYPE html>
    <html lang="tr">
    <head>
    <meta charset="UTF-8">
    <title>ClinicaMatch Platformu Genel Bakış</title>
    </head>
    <body>
        <h1>Kullanım</h1>
        <ol>
            <li><strong>İlk Sorgu Girişi:</strong> Kullanıcılar, herhangi bir özel bilgi veya terim gerektirmeden doğal dilde ilk sorgularını platforma girerler.</li>
            <li><strong>Alakalı Araştırmaların Belirlenmesi:</strong> İlk sorgu üzerine, yapay zekâ ajanı en alakalı klinik araştırmaları tespit eder.</li>
            <li><strong>Doğal Sohbet Ortamı:</strong> Yapay zekâ sohbet botu ve ajanı, doğal bir sohbet ortamı sağlayarak kullanıcının detaylı bilgilerini alır ve merak ettikleri soruları cevaplar.</li>
            <li><strong>Sonuçların Sunulması:</strong> Sohbetin sonunda, kullanıcı kendi sağlık durumu ve ilgi alanlarına en uygun klinik araştırmaları bulabilir.</li>
        </ol>
        <br>
        <h2>Teknolojik Özellikler</h2>
        <ul>
            <li><strong>Semantik Anlama:</strong> Platform, neural-IR gibi ileri teknolojiler kullanarak, kullanıcının niyetini ve bağlamını derinlemesine anlar.</li>
            <li><strong>Kişiselleştirilmiş Yanıtlar:</strong> Büyük yapay zeka dil modelleri, kullanıcıdan alınan bilgilere göre kişiselleştirilmiş, açıklayıcı ve doğru bilgiler üretir.</li>
            <li><strong>Çok Dilli Destek:</strong> Platform, birçok dil desteği sağlar, böylece farklı dilleri konuşan kullanıcılar da platformdan tam olarak faydalanabilir.</li>
        </ul>
        <br>
        <h2>Mevcut Sınırlılıklar</h2>
        <ul>
            <li><strong>Veri Kısıtlılığı:</strong> Şu anda ClinicaMatch'te kullanılan veri tabanı sadece kanserle alakalı küçük bir veri seti ile sınırlıdır.</li>
            <li><strong>Kullanıcı Deneyimi:</strong> Erken aşama olması nedeniyle, kullanıcı arayüzü ve deneyimi daha fazla geliştirilmeye ihtiyaç duyabilir.</li>
        </ul>
        <br>
        <h2>Geliştirme Sürecinin Devamlılığı</h2>
        <ul>
            <li><strong>Veri Setinin Genişletilmesi:</strong> Farklı hastalık türlerini kapsayacak şekilde daha geniş veri setleri entegre edilerek kullanım alanı artırılacaktır.</li>
            <li><strong>Gelişmiş Doğal Dil İşleme & Bilgi Çıkarımı:</strong> Algoritmalar ve teknikler daha karmaşık kullanıcı ihtiyaçlarına uyum sağlayacak şekilde geliştirilecektir.</li>
            <li><strong>Kullanıcı Geri Bildirim Mekanizması:</strong> Kullanıcı deneyimini iyileştirmek adına geri bildirimler toplanacak ve bu bilgilerle platform güncellenecektir.</li>
        </ul>
        <br>
        <h2>Teknolojik Potansiyel</h2>
        <h3>Sağlık Alanında Geliştirilebilecek Uygulama Örnekleri</h3>
        <ol>
            <li><strong>Hastalık Tanı ve Takip Sistemleri:</strong> Hastalıkların erken teşhisinde kullanılmak üzere, semptomlar ve hasta geçmişleri bazında özelleştirilmiş sorgulamalar yapılabilir.</li>
            <li><strong>Tedavi Öneri Sistemi:</strong> Hastanın durumuna özel tedavi yöntemleri öneren bir sistem geliştirilebilir. Bu sistem, kullanıcının sağlık bilgilerini analiz ederek en uygun tedavi yöntemlerini sunar.</li>
            <li><strong>Sağlık Bilgilendirme Portalı:</strong> Kullanıcıların sağlıkla ilgili genel bilgilere kolay erişim sağlaması için bir bilgilendirme portalı oluşturulabilir.</li>
            <li><strong>Uzaktan Sağlık İzleme ve Yönetim:</strong> Kronik hastalıkları olan hastalar için uzaktan izleme ve sağlık yönetimi sistemleri entegre edilebilir.</li>
            <li><strong>Sağlık Araştırmaları ve Veri Analizi:</strong> Büyük sağlık veri setlerinin analizi için araçlar geliştirilerek, epidemiyolojik çalışmalar ve halk sağlığı araştırmaları için önemli bilgiler sağlanabilir.</li>
        </ol>
        <br>
        <h3>Diğer Sektörlerde Uygulamalar</h3>
        <ol>
            <li><strong>Müşteri Hizmetleri ve Destek:</strong> Yapay zekâ destekli sohbet botları, müşteri hizmetleri süreçlerinde kullanılabilir.</li>
            <li><strong>Finans ve Bankacılık:</strong> Finans sektöründe, yapay zekâ tabanlı sistemler, müşteri harcama alışkanlıklarını analiz ederek kişiselleştirilmiş bankacılık tavsiyeleri sunabilir.</li>
            <li><strong>Eğitim Teknolojileri:</strong> Eğitimde, yapay zekâ, öğrencilerin öğrenme stillerini ve performanslarını analiz ederek, onlara uygun öğrenme materyalleri ve bireysel öğrenme yolları önerir.</li>
            <li><strong>Hukuk ve Yönetmelik Uyum:</strong> Hukuk sektöründe, büyük dil modelleri kullanılarak, büyük hukuki belge setlerinden gerekli bilgiler çıkarılabilir ve karmaşık dava dosyaları analiz edilebilir.</li>
            <li><strong>Perakende ve E-ticaret:</strong> Perakende sektöründe, yapay zekâ, müşteri alışveriş verilerini analiz ederek stok yönetimini optimize edebilir ve kişiselleştirilmiş pazarlama stratejileri geliştirebilir.</li>
        </ol>
    </body>
    </html>
        `
    };