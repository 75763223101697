import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Modal from 'react-modal';
import elasticTypeMark from 'images/elasticTypeMark.png';
import { actions } from 'store/provider';
import './Header.css';
import { texts } from './texts'; // Metinleri içeri aktar

export const Header = () => {
  const dispatch = useDispatch();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [language, setLanguage] = useState('TR'); // Dil seçimi için başlangıç değeri olarak 'TR' (Türkçe) ayarlandı

  const handleLogoClick = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch('/api/reset', { method: 'POST' });
      const data = await response.json();
      if (response.ok) {
        dispatch(actions.setSessionId({ sessionId: data.session_id }));
        window.location.reload();
      } else {
        console.error('Sohbeti sıfırlarken bir hata oluştu', data);
      }
    } catch (error) {
      console.error('API isteği sırasında bir hata oluştu', error);
    }
  };

  const toggleModal = () => {
    setModalIsOpen(!modalIsOpen);
  };

  const handleLanguageChange = (event) => {
    setLanguage(event.target.value);
  };

  return (
    <div className="header-container">
      <div className="logo-and-info-section">
        <a href="/" onClick={handleLogoClick}>
          <img src={elasticTypeMark} alt="Logo" className="logo" />
        </a>
        <span className="info-text" onClick={toggleModal}>
          <strong style={{ color: '#034387', fontSize: '15px' }}>ClinicaMatch®</strong>    <span style={{ color: '#034387' }}>GetInfo</span>
        </span>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={toggleModal}
        contentLabel="Uygulama Hakkında"
        ariaHideApp={false}
        className="modal-content"
        overlayClassName="modal-overlay"
      >
        <div style={{ marginBottom: '20px' }}>
          <select onChange={handleLanguageChange} value={language} className="language-selector">
            <option value="EN">English</option>
            <option value="TR">Türkçe</option>
          </select>
        </div>
        <h1 style={{ fontWeight: 'bold' }}>{language === 'EN' ? "About ClinicaMatch" : "ClinicaMatch Hakkında"}</h1>
        <div dangerouslySetInnerHTML={{ __html: language === 'EN' ? texts.aboutEN : texts.aboutTR }}></div>
        <button onClick={toggleModal} style={{ fontWeight: 'bold' }}>{language === 'EN' ? "Close" : "Kapat"}</button>
      </Modal>
    </div>
  );
};